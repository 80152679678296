import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services';
import { finalize } from 'rxjs/operators';
import { LoaderConstants } from '../helpers/Loader.constants';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requestCount = 0;
  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipCount = request.headers.has(LoaderConstants.HEADER);
    if (!skipCount) {
      this.requestCount++;
      this.loaderService.loaderOn = true;
    }

    const modifiedReq = skipCount ? request.clone({ headers: request.headers.delete(LoaderConstants.HEADER) }) : request;
    return next.handle(modifiedReq).pipe(finalize(() => {
      if (!skipCount) {
        this.requestCount--;
        if (this.requestCount === 0) {
          this.loaderService.loaderOn = false;
        }
      }
    }));
  }
}
