import { Injectable, Injector } from '@angular/core';
import { AuthInterceptor } from './auth.interceptor';
import { CmxMsalInterceptor } from '../mfa/interceptors/cmx-msal.interceptor';
import { MsalBroadcastService, MsalInterceptor } from '@azure/msal-angular';
import { B2CService } from '../mfa/services/b2c.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorFactory {
  constructor(
    private injector: Injector,
    private b2cService: B2CService
  ) {}

  createInterceptor() {
    const url = window.location.href;
    if (url.includes('/login/auth') || this.b2cService.isLoginThroughB2C()) {
      return this.injector.get(MsalInterceptor);
    } else {
      return this.injector.get(AuthInterceptor);
    }
  }
}