import {
  IUserNotificationItem,
  IUserNotificationStatisticsItem,
  IUserNotificationCount,
} from './interfaces/user-notification-entry.dto';

import { HttpCemex } from "../../../../../../angular-services-v8/http.service";

import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  Renderer2,
  Optional,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from "../../../../../../angular-services-v8/session.service"
import { CountryConfigService } from "../../../../../../angular-services-v8/country-config.service"
import { TranslationService } from "../../../../../../angular-localization-v7/services/translation.service";
import { IUserProfile } from "../../../../../../angular-types-v2/interfaces";
import { Broadcaster } from "../../../../../../events-v7/projects/events-v7/src/lib/broadcaster.event";
import { Subscription, throwError, fromEvent } from 'rxjs';
import { Http, Response } from '@angular/http';
import {
  ISearchHeaderResult,
  ISearchHeaderService,
  INotificationEntry,
  INotificationService,
  INotificationDict,
} from './interfaces';
import { ICountryConfig } from "../../../../../../angular-types-v2";
import { UserNotificationService } from './services/user-notification.service';
import { refreshIntervalForUserNotifications } from './services/user-notification.config-data';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CwcModal, CwcPopover } from '@cmx-web-components/angular';
export interface IUserLanguage {
  languageId: string;
  languageISO: string;
  isSelected: boolean;
  displayName: string;
  dummyLang?: boolean;
}

@Component({
  selector: 'cmx-nav-header',
  styleUrls: [
    './../../../../../../../../scssv4/cmx-components/cmx-nav-header/v4/cmx-nav-header.component.scss',
    './../../../../../../../../scss/cmx-nav-header/v8/cmx-nav-header.component.scss',
  ],
  templateUrl: './cmx-nav-header.component.html',
})
export class CmxNavHeaderComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  public static USE_TRANSLATION_SERVER: string = 'USE_TRANSLATION_SERVER';

  public _enableNotifications: boolean;
  public _countryConfig: ICountryConfig;

  @ViewChild('countrySupportContactModal') public countrySupportContactModal: CwcModal;
  @ViewChild('notificationDetailDialog') public notificationDetailDialog: CwcModal;

  @ViewChild('helpPopover') public helpPopover: CwcPopover;

  @ViewChild('notificationsPopover') public notificationsPopover: CwcPopover;

  @ViewChild('consolesPopover') public consolesPopover: CwcPopover;

  @ViewChild('profilePopover') public profilePopover: CwcPopover;

  @Output()
  public clickMenuButton: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onSelectItemSearchEvent: EventEmitter<ISearchHeaderResult> = new EventEmitter<ISearchHeaderResult>();

  @Output()
  public onViewAllResultEvent: EventEmitter<ISearchHeaderResult[]> = new EventEmitter<ISearchHeaderResult[]>();
  /**
   * @deprecated Subscribe to Broadcaster's DCM_APP_LOGOUT event instead
   */
  @Output()
  public onLogout: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onLanguageChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public sidenavToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public rtl: boolean = false;

  @Input() public useLanguageSelector: boolean = false;

  @Input() public showCountrySupportContact: boolean = true;

  @Input() public useInternalSettings: boolean = false;
  @Input() public customPathSettings: string;

  @Input() public closeNotificationsOnCLick: boolean = false;

  @Input() public useInternalUserNotifications: string;

  @Input() public customPathUserNotifications: string;

  @Input() public disabledProfile: boolean = false;

  @Input() public version: number = 3;

  @Input() public menuButtonLabel: string = 'Toggle Sidebar';

  @Input() public variant: string;

  @Input() public qualtricsId = 'Q_ZID=ZN_9Gh8IPzaMlwxgmq';

  /** Define name of the CDN logo variant to use */
  @Input() public logoVariant = 'cemex_mono_white_v2';

  /** Define name of the CDN logo variant to use in IL */
  @Input() public logoIlVariant = 'readymix.co.il.white';

  /** Define when the legal entity selector modal is open */
  @Input() public isModalOpen = false;

  @Input() public logoutPath: string;

  @Input() public isValidUser = true;

  public feedbackDialogOpen: boolean = false;

  public showMyProfile = ((window['CX_SHOW_MYPROFILE'] && (window['CX_SHOW_MYPROFILE'] === "true" ||
  window['CX_SHOW_MYPROFILE'] === true)) ? true : false);

  public get _notificationsToShow(): INotificationEntry[] {
    const result: any = this._allNotifications.sort((notif1: INotificationEntry, notif2: INotificationEntry) => {
      if (notif1.typeNotification === notif2.typeNotification) {
        if (notif1.viewed === notif2.viewed) {
          return this.compareNotificationFn(notif1, notif2);
        } else {
          return notif1.viewed ? 1 : -1;
        }
      } else {
        return notif1.typeNotification > notif2.typeNotification ? 1 : -1;
      }
    });
    return result.slice(0, 5);
  }

  // ****************** User Notifications *************************
  public _refreshIntervalForUserNotifications: number = refreshIntervalForUserNotifications;
  public _enableUserNotifications: boolean;
  public _enableUserNotificationsDetail: boolean;
  public _intervalObj4UserNotifications: any;
  public _userNotificationUnreadCount: number = 0;
  public _userNotificationTotalCount: number; // = 0;
  public _userNotificationShortList: IUserNotificationItem[] = [];
  public _userNotificationSummary: string = '';
  public _userNotificationContent: string = '';
  public _showUserNotifications: boolean = false; // if empty: empty message
  public _userNotificationsFirstLoad: boolean = false; // have notifications been loaded for the first time?
  public _userNotificationLoading: boolean = false;
  // ********************************************************************

  public developerPortalUrl: SafeResourceUrl;
  public _formLangUrl: SafeResourceUrl;
  public logo: string;
  public userCountry: string;
  public emails: any[] = [];
  public phones: any[] = [];
  public _userProfile: IUserProfile;
  public showAppNavigation: boolean = false;
  public _userInitials: string = '';
  public _userFullName: string = '';
  public _showUserProfile: boolean = false;
  public _showLangList: boolean = false;
  public _languages: IUserLanguage[] = [];
  public _showNotifications: boolean = false;
  public _allNotifications: INotificationEntry[] = [];
  public _unReadNotification: INotificationEntry[] = [];
  public _serviceIcon: any = {
    S1: 'module-customer-information',
    S2: 'module-order-product-catalogue',
    S3: 'module-track',
    S4: 'module-invoices-payments',
    S5: 'module-user-management',
  };
  public _showServiceCenter: boolean = false;
  public isCollapsed: boolean = false;
  // ****************** User Notifications *************************
  private _clickUserNotificationIcon: boolean = false;
  private _allUserNotifications: IUserNotificationItem[] = [];
  // ********************************************************************
  private _openInput: boolean = false;
  private _searchValue: string;
  private _currentLang: string = '';
  private _defaultRoute: string = '/login';
  private _profileRoute: string = '/profile';
  private _blueGreenCookieName: string = 'testingmode';
  private _blueGreenCookieValue: string = 'all';
  private _activeSearch: boolean = false;
  private _openSearchList: boolean = false;
  private _subscriptionList: ISearchHeaderService[];
  private _resultSearchList: ISearchHeaderResult[] = [];
  private _openWaiting: boolean = false;
  private _noContainsData: boolean = false;
  private _currentIndexSearchList: number = -1;
  private _activeSearchResult: ISearchHeaderResult | undefined;
  private _clickProfileIcon: boolean = false;
  private _useTranslationServer: boolean = false;
  private _showIconNotification: boolean = false;
  private _clickNotificationIcon: boolean = false;
  private _allNotificationDict: INotificationDict = {};
  private _allRawNotifications: any[] = [];
  private _notificationSubscription: Subscription;
  private _notificationService: INotificationService;
  private _translationSuscriber: Subscription;
  private _clickAppsIcon: boolean = false;
  private _userInfo: Subscription;

  get language(): IUserLanguage | any {
    const tmp: IUserLanguage[] = this._languages.filter((lang: any) => lang.isSelected);
    return tmp.length > 0 ? tmp[0] : undefined;
  }

  @Input()
  set activeSearch(value: boolean) {
    this._activeSearch = value;
  }

  @Input()
  set searchServiceList(value: ISearchHeaderService[]) {
    this._subscriptionList = value;
  }

  @Input()
  set notificationService(value: INotificationService) {
    this._notificationService = value;
  }

  @Output()
  private notificationAction: EventEmitter<INotificationEntry> = new EventEmitter<INotificationEntry>();

  @ViewChild('searchInput')
  private searchInput: ElementRef;

  @Output()
  private showAllNotifications: EventEmitter<INotificationEntry[]> = new EventEmitter<INotificationEntry[]>();

  constructor(
    private sessionService: SessionService,
    public _translation: TranslationService,
    private eventBroadcaster: Broadcaster,
    private countryConfigService: CountryConfigService,
    public renderer: Renderer2,
    public http: Http,
    public httpCemex: HttpCemex,
    public userNotificationService: UserNotificationService,
    public router: Router,
    private sanitizer: DomSanitizer,
    @Optional() @Inject('RTL') isRTL: boolean,
  ) {
    if (isRTL !== undefined) {
      this.rtl = isRTL;
    }
    this.setUserInfo();
    this.setLanguages();

    this.setFeedback();
    this.setAppNavigation();

    // check if we got permissions to see the Service center header div
    const userApplications = this.sessionService.applicationsList;
    if (userApplications) {
      const scApp = userApplications.filter(app => app.applicationCode === 'ServiceCenter_App');

      if (scApp.length > 0) {
        const scRoleEntry = scApp[0].roles.filter(role => role.roleCode === 'SC_CASE_VIEW');
        // UNCOMMENT WHEN SUPPORT CENTER IS READY
        // this._showServiceCenter = scRoleEntry.length > 0;
      }
    }
  }

  public setAppNavigation(): void {
    this.developerPortalUrl = this.sanitizer.bypassSecurityTrustUrl('https://developers.cemexgo.com/');
  }

  public goToDeveloperPortal(): void {
    this.consolesPopover.toggle();
  }

  public openFeedbackDialog(): void {
    if (this.version === 3) {
      this.helpPopover.toggle();
    }
    this.feedbackDialogOpen = true;
  }

  /**
   * @description This method is used to open the feedback dialog
   */
  public feedbackClick() {
    // We use the same logic above and do not affect the walkme
    const feedbackButton = document.getElementById('cmx-header-feedback');
    if (feedbackButton) {
      feedbackButton.click();
    }
  }

  public closeFeedbackDialog(): void {
    this.feedbackDialogOpen = false;

    // we unload and reload the form each time we close the modal
    // because if we submit the form, there is no other programatic way
    // to get rid of the thank you message and open the feedback form again.
    const QAPI = window['QSI'].API;
    if (QAPI) {
      QAPI.unload();
      QAPI.load().then(QAPI.run());
    }
  }

  public setFeedback(): void {
    const country: string = 'country';
    this.userCountry = this.sessionService.country;
    this.logo = this.userCountry === 'IL' ? this.logoIlVariant + '.svg' : this.logoVariant + '.svg';
    this.logo =
      this._userProfile &&
      this._userProfile.customerId === 3025 &&
      this.userCountry === 'MX' &&
      (window.location.hostname.includes('dev2') || window.location.hostname.includes('demo'))
        ? 'neoris-logo.svg'
        : this.logo;
    if (this.userCountry === undefined) {
      return;
    }
    switch (this.userCountry) {
      case 'CO':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-colombia.ideas.aha.io');
        break;
      case 'CR':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://cxgofeedback-costarica.ideas.aha.io',
        );
        break;
      case 'CZ':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://cxgofeedback-czechrepublic.ideas.aha.io',
        );
        break;
      case 'EG':
        /* keeping formLangUrl as undefined so is not displayed on DOM.
          this._formLangUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl("https://cxgofeedback-egypt.ideas.aha.io"); */
        break;
      case 'SV':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://cxgofeedback-elsalvador.ideas.aha.io',
        );
        break;
      case 'DE':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-germany.ideas.aha.io');
        break;
      case 'GT':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://cxgofeedback-guatemala.ideas.aha.io',
        );
        break;
      case 'IL':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-israel.ideas.aha.io');
        break;
      case 'LV':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-latvia.ideas.aha.io');
        break;
      case 'MX':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://www.cemexgo.com/cdn/jiraIssueCollectors/cxgofeedback-mexico.html',
        );
        break;
      case 'NI':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://cxgofeedback-nicaragua.ideas.aha.io',
        );
        break;
      case 'PA':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-panama.ideas.aha.io');
        break;
      case 'PE':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-peru.ideas.aha.io');
        break;
      case 'PH':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://cxgofeedback-philippines.ideas.aha.io',
        );
        break;
      case 'PL':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-poland.ideas.aha.io');
        break;
      case 'PR':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://cxgofeedback-puertorico.ideas.aha.io',
        );
        break;
      case 'DO':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://cxgofeedback-republicadominicana.ideas.aha.io',
        );
        break;
      case 'GB':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-uk.ideas.aha.io');
        break;
      case 'US':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://www.cemexgo.com/cdn/jiraIssueCollectors/cxgofeedback-usa.html',
        );
        break;
      case 'FR':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-france.ideas.aha.io');
        break;
      case 'ES':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-spain.ideas.aha.io');
        break;
      case 'AE':
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-uae.ideas.aha.io');
        break;
      default:
        this._formLangUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://cxgofeedback-usa.ideas.aha.io');
        break;
    }
  }

  public ngOnInit(): void {
    this.eventBroadcaster.on<string>(Broadcaster.DCM_LANGUAGE_CHANGE).subscribe(() => {
      this.setFeedback();
    });

    if (this.showCountrySupportContact && this.isValidUser) {
      this.getConfigCountry();
    }

    this._enableNotifications = this._notificationService && 'serviceWorker' in navigator;

    if (this._enableNotifications && this.isValidUser) {
      navigator.serviceWorker.addEventListener('message', (event: any) => {
        const notification: any = event.data;
        // convert raw notification into a INotificationEntry to know its type notification
        const pushedNotification: any = this._notificationService.mapNotification(notification);
        // get list of notifications by type notification
        const _notifications: any = this._allNotificationDict[pushedNotification.typeNotification];
        // add push notification to list of notifications
        this._allNotifications.push(pushedNotification);
        _notifications.push(pushedNotification);
        // notify new push notification to the INotificationService
        this._notificationService.notificationSubscription.next({
          notifications: _notifications,
          typeNotification: pushedNotification.typeNotification,
        });
        // this subscription is used when service worker get a new notification
        this._notificationService.pushedNotification.next({
          notifications: [notification],
          typeNotification: pushedNotification.typeNotification,
        });

        this._unReadNotification = this._allNotifications.filter((n: INotificationEntry) => !n.viewed);
      });
    }
    // --- UserNotifications setup ---
    this._enableUserNotifications =
      !(this.useInternalUserNotifications === 'disabled') &&
      (sessionStorage.getItem('notificationsFeature') === 'enabled' || this.useInternalUserNotifications === 'enabled');

    this._enableUserNotificationsDetail = false; // forced
    if (this._enableUserNotifications && this.isValidUser) {
      this.startUserNotificationsLoop();
    }

    this._userInfo = this.sessionService.userInfo.subscribe((result: boolean) => {
      if (result) {
        this.setUserInfo();
      }
    })
  }

  public loadQualtrics(): void {
    function loadScript(url, callback) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = function() {
        callback();
      };
      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    loadScript('https://zn9gh8ipzamlwxgmq-cemexglobal.siteintercept.qualtrics.com/SIE/?' + this.qualtricsId, () => {
      // The qualtrics api is instantiated and run
      const QAPI = window['QSI'].API;
      if (QAPI) {
        QAPI.run();
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this.searchInput !== undefined) {
      const inputStream: any = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        debounceTime(1),
        distinctUntilChanged(),
      );

      inputStream.subscribe((event: any) => {
        // console.log('print event key:', event);
        const listResultLength: number = this.getListResult().length;
        if (event.code === 'Enter' && event.target.value && event.target.value !== '') {
          this._resultSearchList = [];
          this._openSearchList = true;
          this._openWaiting = true;
          if (this._activeSearchResult) {
            this.onClickResultItem(this._activeSearchResult);
            this._activeSearchResult = undefined;
            return;
          }

          for (const subscription of this._subscriptionList) {
            const localSub: any = subscription.executeService(event.target.value).subscribe(
              (result: any) => {
                this._resultSearchList = this._resultSearchList.concat(result);
                this._noContainsData = this._resultSearchList.length === 0;
                this._openWaiting = this._noContainsData;
                this._currentIndexSearchList = -1;
                this._activeSearchResult = undefined;
              },
              (error: any) => {
                this._noContainsData = true;
                console.warn(error);
                localSub.unsubscribe();
              },
            );
          }
        } else if (event.code === 'ArrowDown' && this._resultSearchList.length > 0) {
          this._currentIndexSearchList++;
          this._currentIndexSearchList =
            this._currentIndexSearchList >= listResultLength ? 0 : this._currentIndexSearchList;
          this._activeSearchResult = this._resultSearchList[this._currentIndexSearchList];
        } else if (event.code === 'ArrowUp' && this._resultSearchList.length > 0) {
          this._currentIndexSearchList--;
          this._currentIndexSearchList =
            this._currentIndexSearchList < 0 ? listResultLength - 1 : this._currentIndexSearchList;
          this._activeSearchResult = this._resultSearchList[this._currentIndexSearchList];
        } else if (event.code === 'Escape') {
          this._searchValue = '';
          this._openSearchList = false;
          this._resultSearchList = [];
        } else {
          this._activeSearchResult = undefined;
          this._resultSearchList = [];
        }
      });
    }
    this.loadQualtrics();
    let attempts = 12;
    // interval for 1 min to check if there are walkme scripts in the head
    const interval = setInterval(() => {
      const walkmeScript: any = document.querySelector('.walkme-launcher-container');
      if (walkmeScript) {
        clearInterval(interval);
      }
      if (attempts <= 0) {
        const walkmeButton: any = document.getElementById('cmx-header-walkme-button');
        if (walkmeButton) {
          walkmeButton.remove();
        }
        clearInterval(interval);
      }
      attempts--;
    }, 5000);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['notificationService'] && changes['notificationService'].currentValue) {
      this._notificationSubscription = this._notificationService
        .executeNotificationService()
        .subscribe((notificationsDict: any) => {
          // just comment it should clean _allNotifications in every call
          this._allNotifications = [];
          const tmpKeys: string[] = Object.keys(notificationsDict);
          const notificationKeys: string[] = tmpKeys.sort();

          for (const notificationType of notificationKeys) {
            this._allNotifications = this._allNotifications.concat(notificationsDict[notificationType]);
            const rawNotifications: any[] = [];
            for (const notification of notificationsDict[notificationType]) {
              rawNotifications.push(notification.notificationData);
            }

            this._notificationService.notificationSubscription.next({
              notifications: rawNotifications,
              typeNotification: Number(notificationType),
            });
          }
          this._unReadNotification = this._allNotifications.filter(
            (notification: INotificationEntry) => !notification.viewed,
          );
        });
    }

    this._showIconNotification = this._notificationService !== null;
  }

  public ngOnDestroy(): void {
    this._translationSuscriber.unsubscribe();
    this._userInfo.unsubscribe();
    this.clearUserNotificationsLoop();
  }

  public setUserInfo(): void {
    if (this.sessionService.userProfile && this.sessionService.userProfile !== undefined) {
      this._userProfile = this.sessionService.userProfile;
      const firstName: any =
        this._userProfile.firstName === 'undefined' || (this._userProfile.firstName ? this._userProfile.firstName.trim() === null : true)
          ? ''
          : this._userProfile.firstName.substring(0, 1);
      const lastName: any =
        typeof this._userProfile.lastName === 'undefined' || (this._userProfile.lastName ? this._userProfile.lastName.trim() === null : true)
          ? ''
          : this._userProfile.lastName.substring(0, 1);
      this._userInitials = firstName + lastName;
      const nameUser = this._userProfile.firstName === 'undefined' ? '' : this._userProfile.firstName ? this._userProfile.firstName.trim() : '';
      const lastNameUser = this._userProfile.lastName === 'undefined' ? '' : this._userProfile.lastName ? this._userProfile.lastName.trim() : '';
      this._userFullName = (nameUser + ' ' + lastNameUser).trim();
    }
  }

  public showProfile(): void {
    this._clickProfileIcon = true;
    this._showUserProfile = !this._showUserProfile;
  }

  public setLanguages(): void {
    this._translationSuscriber = this._translation.getLanguages().subscribe(
      (languages: any) => {
        this._languages = languages;
      },
      (error: any) => {
        console.warn('Error at setting languages:', error);
      },
    );
  }

  public openCountrySupportContactModal(): void {
    if (this.version === 3) {
      this.helpPopover.toggle();
    }
    this.countrySupportContactModal.open = true;
    this._showUserProfile = !this._showUserProfile;
  }

  public openNotificationDetailDialog(): void {
    this.notificationDetailDialog.open = true;
  }

  public onClickSearch(): void {
    this._openInput = true;
    if (this.searchInput) {
      this['renderer2'].invokeElementMethod(this.searchInput.nativeElement, 'focus', []);
    }
  }

  public onClickCancelSearch(): void {
    this._openInput = false;
    this._searchValue = '';
    this._openSearchList = false;
  }

  public gotoHome(): void {
    window.location.href = '/';
  }

  public openLearningHub(): void {
    if (this.version === 3) {
      this.helpPopover.toggle();
    }
    window.open(this._countryConfig.learningHubLink);
  }

  public goToSettings(): void {
    if (this.version === 3) {
      this.profilePopover.toggle();
    }
    const path: string = this.customPathSettings && this.useInternalSettings ? this.customPathSettings : '/settings';
    if (this.useInternalSettings) {
      this._showUserProfile = false;
      this.router.navigate([path]);
    } else {
      window.location.href = path;
    }
  }

  public goToSupportCenter(): void {
    // navigate to support center (TO DO)
    if (this.version === 3) {
      this.helpPopover.toggle();
    }
  }

  public goToUserNotifications(): void {
    if (this.version === 3) {
      this.notificationsPopover.toggle();
    }
    const path: string =
      this.customPathUserNotifications && this.useInternalUserNotifications
        ? this.customPathUserNotifications
        : '/user-notifications';
    if (this.useInternalUserNotifications) {
      // this._showUserProfile = false;
      console.log('Navigate route path: ', path);

      this.router.navigate([path]);
    } else {
      console.log('Navigate w.location path: ', path);
      window.location.href = path;
    }
  }

  public createCookie(name: string, value: string, days?: number): void {
    let expires: any = '';
    if (days) {
      const date: Date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = date.toUTCString();
    } else {
      days = 0;
    }
    const domain: string = (window as any).SITE_DOMAIN || '';
    const cookie: string = `${name}=${value};expires=${expires};domain=${domain || ''};path=/`;
    document.cookie = cookie;
  }

  public isBlueGreen(): boolean {
    return this.getCookie(this._blueGreenCookieName) === this._blueGreenCookieValue ? true : false;
  }

  public toggleAppNavigation(): void {
    this._clickAppsIcon = true;
    this.showAppNavigation = !this.showAppNavigation;
  }

  public clickAppNavigationOutside(event: any): void {
    if (!this._clickAppsIcon) {
      this.showAppNavigation = false;
    }
    this._clickAppsIcon = false;
  }

  public clickOutside(event: any): void {
    if (!this._clickProfileIcon) {
      this._showUserProfile = false;
    }
    this._clickProfileIcon = false;
  }

  public showNotifications(): void {
    this._clickNotificationIcon = true;
    if (this._allNotifications.length > 0) {
      this._showNotifications = !this._showNotifications;
    }
  }

  public clickNotificationOutSide(event: any): void {
    if (!this._clickNotificationIcon) {
      this._showNotifications = false;
    }
    this._clickNotificationIcon = false;
  }

  public logout(): void {
    if (this._notificationSubscription) {
      this._notificationSubscription.unsubscribe();
    }
    this.createCookie('up_session', '', -1);
    this.sessionService.logout().subscribe((result: any) => {
      if (!result.success) {
        console.error('An error ocurred before logout:', result.message);
      }
      if (this.logoutPath) {
        window.location.href = this.logoutPath;
      } else {
        window.location.href = '/';
      }
    });
  }

  public goToProfile(): void {
    if (!this.isValidUser) {
      return;
    }
    if (this.version === 3) {
      this.profilePopover.toggle();
    }
    if (!this.disabledProfile) {
      this.router.navigate(['/profile']);
    }
  }

  public selectLang(language: IUserLanguage): void {
    // fix for DCM021CX0118-4041
    sessionStorage.setItem('language', language.languageISO);
    this._translation.setLanguage(language.languageISO);
    this.onLanguageChange.emit();
    this._showUserProfile = false;
    this.eventBroadcaster.broadcast(Broadcaster.DCM_LANGUAGE_CHANGE, language.languageISO);
  }

  public callNotificationAction(notification: INotificationEntry): void {
    notification.viewed = true;
    this._notificationService
      .updateNotification([notification.notificationData], notification.typeNotification)
      .subscribe();
    if (this.closeNotificationsOnCLick) {
      this._showNotifications = false;
    }
    this.notificationAction.emit(notification);
  }

  public onAllNotification(): void {
    if (this.closeNotificationsOnCLick) {
      this._showNotifications = false;
    }
    this.showAllNotifications.emit(this._allNotifications);
  }

  public getIconNotification(notification: INotificationEntry): string {
    const result: any =
      notification.notificationIcon === undefined ? 'icon-warning-red' : notification.notificationIcon;
    return !notification.viewed ? result : 'icon-no-fill';
  }
  // ****************** User Notifications *************************

  // ****************** User Notifications PUBLIC ************************* INI
  // enable / clear interval refreshing
  public startUserNotificationsLoop(): void {
    if (this._enableUserNotifications) {
      this.refetchNotificationsCount();
      this._intervalObj4UserNotifications = setInterval(() => {
        this.refetchNotificationsCount();
      }, this._refreshIntervalForUserNotifications);
    } else {
      this.clearUserNotificationsLoop();
    }
  }

  public clearUserNotificationsLoop(): void {
    if (this._intervalObj4UserNotifications) {
      clearInterval(this._intervalObj4UserNotifications);
    }
  }

  public refetchNotificationsCount(): void {
    this.userNotificationService.getStatistics().subscribe(
      (v: IUserNotificationStatisticsItem[]) => {
        let stats: IUserNotificationCount = this.userNotificationService.resetStatisticCounters();
        stats = this.userNotificationService.setStatisticsCounters(v);

        this._userNotificationUnreadCount = stats.newCount;

        this._userNotificationTotalCount = stats.totalCount ? stats.totalCount : 0;
      },
      (err: any) => {
        console.error('its page 1 error:', err);
      },
    );
  }

  public refetchNotificationsUnread(): void {
    this.userNotificationService.getNotificationsPage().subscribe(
      (v: any) => {
        this._userNotificationsFirstLoad = true; // set first load as true to hide loader
        const vList: IUserNotificationItem[] = this.userNotificationService.setMessageList(v);
        // this._userNotificationShortList = [...vList];
        this._userNotificationShortList = [].concat(vList);
        this._userNotificationTotalCount = vList.length;
        if (this._userNotificationTotalCount > 0) {
          this.userNotificationService.setNewStatusAsViewed().subscribe(
            (res: any) => {
              return;
            },
            (err: any) => {
              console.error('Error while setNewStatusAsViewed(): ', err);
              return;
            },
          );
        }
      },
      (err: any) => {
        console.error('its page 1 error:', err);
        return;
      },
    );
  }

  public showUserNotifications(): void {
    if (!this.isValidUser) {
      return;
    }
    this._userNotificationUnreadCount = 0;
    this._clickUserNotificationIcon = true;
    this._showUserNotifications = !this._showUserNotifications;

    if (this._showUserNotifications) {
      this.refetchNotificationsUnread();
    }
    if (!this._showUserNotifications) {
      this._userNotificationShortList = this._userNotificationShortList.map((m: IUserNotificationItem) => {
        return {
          ...m,
          statusCode: m.statusCode === 'N' ? 'V' : m.statusCode,
        };
      });
    }
  }

  private parseNotificationContent = htmlContent => {
    // a function that gives you all the text nodes within an element
    function textNodesUnder(el) {
      let n;
      const a = [];
      const walk = document.createNodeIterator(el, NodeFilter.SHOW_TEXT, null);
      while ((n = walk.nextNode())) {
        a.push(n);
      }
      return a;
    }
    // a function to determine if a style value of color is white.
    function isWhite(color) {
      const whiteVariants = ['white', '#fff', '#ffffff', 'rgb(255, 255, 255)', 'rgba(255, 255, 255, 1)'];
      if (color.indexOf('hsl(') > -1) {
        return color.indexOf('100%)') > -1;
      } else {
        return whiteVariants.indexOf(color) > -1;
      }
    }

    function removeEmpty(parent) {
      let removed = false;
      // for each child
      [].forEach.call(parent.children, function (child: { matches: (arg0: string) => any; }) {
        // repeat operation
        removeEmpty(child);

        // remove if it matches selector
        if (child.matches(':empty')) {
          parent.removeChild(child);
          removed = true;
        }
      });

      if (removed) {
        removeEmpty(parent);
      }
    }

    // get all the textnodes and check the ones that got white text (the ones in the footer)
    const tempElement = document.createElement('div');
    tempElement.style.opacity = '0';
    tempElement.style.position = 'fixed';
    tempElement.style.top = '0';
    tempElement.style.left = '0';
    tempElement.style.pointerEvents = 'none';

    tempElement.innerHTML = htmlContent;

    // remove unwanted attributes
    const attrRemovals = ['align', 'width', 'height', 'class'];

    attrRemovals.forEach(attr => {
      tempElement.querySelectorAll(`[${attr}]`).forEach(el => {
        el.removeAttribute(attr);
      });
    });

    document.querySelector('body').appendChild(tempElement);

    // remove unwanted tags
    const tagRemovals = ['img', 'style', 'title'];

    tagRemovals.forEach(tag => {
      tempElement.querySelectorAll(tag).forEach(el => {
        el.parentNode.removeChild(el);
      });
    });

    // remove images
    const images = tempElement.querySelectorAll('img');
    images.forEach(img => {
      img.parentNode.removeChild(img);
    });

    const textNodes = textNodesUnder(tempElement);
    textNodes.forEach(textNode => {
      // get the color
      const styles = window.getComputedStyle(textNode.parentNode);
      const isTextWhite = isWhite(styles.color);

      // check if it's empty
      const textContent = textNode.nodeValue;
      const nbsp = textContent.match(/\u00a0/g);
      const isEmpty = textContent === ' ' || textContent === '' || nbsp;
      if (isTextWhite || isEmpty) {
        textNode.parentNode.removeChild(textNode);
      }
    });

    // remove empty elements recursively
    removeEmpty(tempElement);

    // strip styles
    const styledElements = tempElement.querySelectorAll('[style]');

    styledElements.forEach(styledElement => {
      // hold font size and font weight;
      const el = styledElement as HTMLElement;
      const styles = window.getComputedStyle(el);

      const fontWeight = styles.fontWeight;

      el.removeAttribute('style');
      el.style.fontWeight = fontWeight;
    });

    // we assume the first text element is the heading
    const heading = textNodesUnder(tempElement)[0];
    heading.parentNode.classList.add('cmx-notifications__modal-detail-heading');

    // add styles programatically
    // (adding them in the css files makes them encapsulated)
    // and are not recognized
    const baseClass = '.cmx-notifications__modal-detail';
    const baseStyles = `
    ${baseClass} {
      font-size: 1rem;
    }
    ${baseClass} h1,
    ${baseClass}-heading {
      padding-bottom: 1em !important;
      font-size:1.75rem;
      display: block;
    }

    ${baseClass} p {
      margin-bottom: 1em;
    }
    ${baseClass} table td {
      font-size: 1rem !important;
      padding-inline-end: 0.5rem;
    }
    `;
    const st = document.createElement('style');
    st.appendChild(document.createTextNode(baseStyles));
    tempElement.appendChild(st);

    const output = tempElement.innerHTML;

    tempElement.parentNode.removeChild(tempElement);

    return output;
  };

  public showUserNotificationDetail(notificationId: number): void {
    // set the loader
    this._userNotificationLoading = true;
    if (this.version === 3) {
      this.notificationsPopover.toggle();
    }
    this.userNotificationService.getNotificationDetail(notificationId).subscribe(
      (v: IUserNotificationItem) => {
        this._userNotificationSummary = v.summary;
        this._userNotificationContent = this.parseNotificationContent(v.content);
        this._userNotificationLoading = false;
        // #TODO: verify spinner
        this.userNotificationService.setNotificationAsRead(notificationId).subscribe(
          (res: any) => {
            return res;
          },
          (err: any) => {
            console.error('SVC ERR setNotificationAsRead:', err);
            return throwError(err);
          },
        );
      },
      (err: any) => {
        console.error('SVC setNotificationAsRead error:', err);
      },
    );
  }
  // ******* User Notifications PUBLIC methods  ******* FIN
  private getCookie(name: string): string {
    const match: RegExpMatchArray = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`)) || [];
    return match[2] ? match[2] : '';
  }

  private getListResult(): ISearchHeaderResult[] {
    return this._resultSearchList.length > 2 ? this._resultSearchList.slice(0, 2) : this._resultSearchList;
  }

  private onClickResultItem(item: ISearchHeaderResult): void {
    this.onSelectItemSearchEvent.emit(item);
    this._openSearchList = false;
  }

  private onClickViewAll(): void {
    this.onViewAllResultEvent.emit(this._resultSearchList);
    this._openSearchList = false;
  }

  private getMessageResult(noContainsData: boolean): string {
    return noContainsData ? 'Data not found' : 'Searching Data...';
  }

  private compareNotificationFn(notif1: INotificationEntry, notif2: INotificationEntry): number {
    if (notif1.notificationDate === notif2.notificationDate) {
      return 0;
    } else if (notif1.notificationDate > notif2.notificationDate) {
      return 1;
    } else {
      return -1;
    }
  }

  private getConfigCountry(): void {
    this.countryConfigService.countryConfig.subscribe(
      (configCountry: ICountryConfig) => {
        if (configCountry !== null) {
          this._countryConfig = configCountry;
          if (this._countryConfig.helpCenterEmail && Boolean(this._countryConfig.helpCenterEmail)) {
            const emailsArr: any = this._countryConfig.helpCenterEmail.split(',');
            this.emails = emailsArr;
          }

          if (this._countryConfig.helpCenterPhoneNumber && Boolean(this._countryConfig.helpCenterPhoneNumber)) {
            const phonesArr: any = this._countryConfig.helpCenterPhoneNumber.split(',');
            this.phones = phonesArr;
          }

          if (!Boolean(this._countryConfig.helpCenterEmail) && !Boolean(this._countryConfig.helpCenterPhoneNumber)) {
            this.showCountrySupportContact = false;
          }
        }
      },
      (error: any) => {
        console.error(`Unable to retrieve country information for ${this.sessionService.country}`, error);
      },
    );
  }

  clickUserNotificationOutSide(event: any): void {
    if (!this._clickUserNotificationIcon) {
      this._showUserNotifications = false;
    }

    this._clickUserNotificationIcon = false;
  }

  sidenavToggleClick() {
    this.isCollapsed = !this.isCollapsed;
    this.sidenavToggle.emit(this.isCollapsed);
  }
  // ****************** User Notifications PRIVATE ************************* FIN
}
