export type FeatureTogglesSwitch = 'all' | 'hidden' | 'api';

export interface Environment {
  production: boolean;
  environment: string;
  basePath: string;
  entryPath: string;
  apiHost: string;
  ibmClientId: string;
  dcmHost: string;
  appFeatures: FeatureTogglesSwitch;
  googleMapsApiKey: string;
  rolloutKey: string;
  countlyKey: object;
  build: string;
  translations: {
    locale: string;
    fallbackLocale: string;
    translateUrl: string;
  };
  cxAppName: string;
}

export interface DILauncherContainer {
  environment: Environment;
}

export interface AppLauncher {
  init(
    mountElement: HTMLDivElement,
    container: DILauncherContainer,
    callback: (status: boolean) => void
  ): void;
  destroy(mountElement: HTMLDivElement): void;
}
