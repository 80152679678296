
import { Injectable } from '@angular/core';
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import * as momentImported from 'moment';
import { CmxHttpClient } from './http-client.service';
import { Observable } from 'rxjs/';
import { map, catchError } from 'rxjs/operators';
import {
  IUserNotificationStatisticsItem,
  IUserNotificationCount,
  IUserNotificationNotifications,
  IUserNotificationItem,
  IUserNotificationModules,
  IUserNotificationModuleDto,
} from '../interfaces/user-notification-entry.dto';
import { CmxHttpOptions } from '../cmx-api-v7/projects/cmx-api-v7/src/public_api';
import { LoaderConstants } from '../helpers/Loader.constants';

const moment: any = momentImported;

@Injectable()
export class UserNotificationV2Service {

  private currentUserNotificationCount: IUserNotificationCount;
  private currentNotificationsList: IUserNotificationItem[];

  constructor(private cmxHttp: CmxHttpClient, private translation: TranslationService) { }
  // ----------------------------------------------------------
  public resetStatisticCounters(): IUserNotificationCount {

    this.currentUserNotificationCount = {
      newCount: 0,
      readCount: 0,
      totalCount: 0,
      viewCount: 0,
    };
    return this.currentUserNotificationCount;
  }

  public setStatisticsCounters(v: IUserNotificationStatisticsItem[]): IUserNotificationCount {
    this.resetStatisticCounters();

    v.forEach(
      (i: IUserNotificationStatisticsItem) => {
        switch (i.statusCode) {
          case 'N':
            this.currentUserNotificationCount.newCount = i.count;
            break;
          case 'V':
            this.currentUserNotificationCount.viewCount = i.count;
            break;
          case 'R':
            this.currentUserNotificationCount.readCount = i.count;
            break;

          default:
            break;
        }

      });


    this.currentUserNotificationCount.totalCount = this.currentUserNotificationCount.newCount +
      this.currentUserNotificationCount.viewCount + this.currentUserNotificationCount.readCount;

    return this.currentUserNotificationCount;
  }

  public setMessageList(v: IUserNotificationNotifications): IUserNotificationItem[] {
    this.currentNotificationsList = [];

    // console.log('Notifications setMessageList INI:', v);

    if (v && v.count > 0 && v.items) {
      const today: string = v.serverDate.substring(0, 10);
      let formatDate: string = this.translation.pt('global.formatDate');
      let formatTime: string = this.translation.pt('global.formatTime');

      if (formatDate.startsWith('NOT:')) { formatDate = 'L'; }
      if (formatTime.startsWith('NOT:')) { formatTime = 'LT'; }

      v.items.forEach(
        (it: IUserNotificationItem) => {
          const t1: string = it.processCode.toUpperCase();
          const t2: string = t1 + '.' + it.subprocessCode.toUpperCase();
          const lProcessCode: string = this.translation.pt(t1);
          const lSubProcessCode: string = this.translation.pt(t2);

          const itm: IUserNotificationItem = {
            content: it.content,
            date: it.date,
            isHtmlContent: it.isHtmlContent,
            notificationId: it.notificationId,
            processCode: it.processCode,
            statusCode: it.statusCode,
            subprocessCode: it.subprocessCode,
            summary: it.summary,

            group: this.setGroupByDayMonthPrev(it.date, v.serverDate),
            isToday: it.date.startsWith(today),
            localDate: moment.utc(it.date).local().format(formatDate),
            localTime: moment.utc(it.date).local().format(formatTime),
            descProcessCode: lProcessCode.startsWith('NOT:') ? it.processCode : lProcessCode,
            descSubProcessCode: lSubProcessCode.startsWith('NOT:') ? it.subprocessCode : lSubProcessCode
          };

          this.currentNotificationsList.push({ ...itm });
        });

      // // #FIXME: in case sort is needed
      // this.currentNotificationsList.sort(
      //   (a: IUserNotificationItem,
      //     b: IUserNotificationItem) => {
      //     if (a.date > b.date) {
      //       return 1;
      //     } else if (a.date < b.date) {
      //       return -1;
      //     } else {
      //       return 0;
      //     }
      //   });

      // console.log('setMessageList in OutSort:', this.currentNotificationsList);
    }

    return this.currentNotificationsList;
  }

  // Set group by comparing to reference ISO date
  private setGroupByDayMonthPrev(itDate: string, isoRef: string) {
    if (itDate.substr(0, 10) === isoRef.substr(0, 10)) {
      return ('D');
    }
    if (itDate.substr(0, 7) === isoRef.substr(0, 7)) {
      return ('M');
    }
    return 'P';

  }
  public getStatistics() {
    const uri = 'v1/its/notifications/statistics';
    const options: CmxHttpOptions = LoaderConstants.SKIP_LOADER_HEADER;
    return this.cmxHttp.get<IUserNotificationStatisticsItem[]>(uri, options);
  }

  public getNotificationsPage(pageNumber = 1, pageSize = 5, dateFrom = '', dateTo = '', processCode = '', subprocessCode = '') {
    const uri = 'v1/its/notifications';
    const utcDateFrom = dateFrom ? moment(dateFrom).utc().format() : '';
    const utcDateTo = dateTo ? moment((dateTo === dateFrom ? moment(dateTo).add(1, 'days').format() : dateTo)).utc().format() : '';
    const queryString = '?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&dateFrom=' + utcDateFrom + '&dateTo=' +
                          utcDateTo + '&processCode=' + processCode + '&subprocessCode=' + subprocessCode;
    return this.cmxHttp.get<IUserNotificationItem[]>(uri + queryString);
  }

  public getNotificationDetail(notificationId: number): Observable<IUserNotificationItem> {
    const uri = `v1/its/notifications/${notificationId}`;
    return this.cmxHttp.get(uri);
  }

  public setNotificationAsRead(notifId: number): Observable<any> {
    return this.setNotificationStatus(notifId, 'R');
  }

  public setNotificationAsViewed(notifId: number): Observable<any> {
    return this.setNotificationStatus(notifId, 'V');
  }

  public setNewStatusAsViewed(): Observable<any> {
    return this.setNotificationsChangeStatus('N', 'V');
  }

  private setNotificationStatus(notifId: number, newStatusCode: string): Observable<any> {
    const uri = `v1/its/notifications/${notifId}/status/${newStatusCode}`;
    return this.cmxHttp.post(uri, null);
  }

  private setNotificationsChangeStatus(oldStatusCode: string, newStatusCode: string): Observable<IUserNotificationItem> {
    const uri = 'v1/its/notifications';
    return this.cmxHttp.post(uri, { newStatusCode, oldStatusCode });
  }

  public shareNotification(notifId: number, to: string, subject: string): Observable<any> {
    const apiUrl: string = 'v1/its/notifications/' + notifId + '/share';
    const req: any = {
      'to': to,
      'subject': subject
    };

    return this.cmxHttp.post(apiUrl, req);
  }

  public getModules(): Observable<IUserNotificationModules> {
    const uri = 'v1/its/notifications/modules';
    return this.cmxHttp.get(uri);
  }
}
