import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { MFAService, MfaMethodName } from './mfa.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class B2CService {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    protected msalService: MsalService,
    private mfaService: MFAService,
    private msalBroadcastService: MsalBroadcastService,
  ) { }

  public isLoginThroughB2C() {
    const loginThroughB2C = sessionStorage.getItem('loginThroughB2C');
    return (loginThroughB2C !== null && loginThroughB2C !== undefined && loginThroughB2C === 'true');
  }

  public logoutB2C() {
    const signinAccount = this.getSigninAccount();
    if (signinAccount) {
      sessionStorage.removeItem('loginThroughB2C');
      this.msalService.logoutRedirect({
        account: signinAccount,
      }).subscribe(result => {
        this.msalService.instance.setActiveAccount(null);
      }, error => {
        console.error('Error during logout:', error);
        this.clearSession();
      });
    }
  }

  public logoutEnrollAccount(mfaMethod: MfaMethodName) {
    const account = this.findEnrollAccount(mfaMethod);
    if (account) {
      this.msalService.logoutRedirect({
        account: account,
        onRedirectNavigate: (url) => {
          return false;
        }
      }).subscribe(result => {}, error => {
        console.error('Error during logout:', error);
        this.clearSession();
      });
    }
  }

  public getSigninAccount() : AccountInfo {
    return this.findAccountByPolicyName(this.mfaService.getSignInPolicyName());
  }

  private findAccountByPolicyName(policyName: string): AccountInfo | undefined {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts) {
      return accounts.find(acc => acc.idTokenClaims.tfp.toUpperCase() === policyName.toUpperCase());
    }
  }

  private findEnrollAccount(mfaMethod: MfaMethodName): AccountInfo | undefined {
    const policyName = this.mfaService.getEnrollPolicyName(mfaMethod);
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts) {
      return accounts.find(acc => acc.idTokenClaims.tfp.toUpperCase() === policyName.toUpperCase());
    }
  }

  public clearSession() {
    sessionStorage.clear();
    localStorage.clear();
    this.msalService.instance.setActiveAccount(null);
  }

  public catchErrorLogin () {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.info('B2C ACQUIRE_TOKEN_SUCCESS done:', result);
      });


    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.error('B2C Login failed:', result.error);
        let error: any = result.error;
        error = this.extractErrorDescription(error.errorMessage);
        sessionStorage.setItem('errorLogin', error);
        this.msalService.logoutRedirect();
      });
  }

  public unsubscribeErrorLogin() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private extractErrorDescription(errorMessage: string): string {
    const descriptionMatch = errorMessage.match(/Description: ([^:]+: [^\.]+)\./);
    return descriptionMatch ? descriptionMatch[1] : '';
  }
}
